import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Channel } from '../../../admin/channels/channel';
import { WebPlayerState } from '../../web-player-state.service';

@Component({
  selector: 'channel-show',
  templateUrl: './channel-show.component.html',
  styleUrls: ['./channel-show.component.scss'],
})
export class ChannelShowComponent implements OnInit {
  @Input() set channel(channel: Channel) {
    this.channel$.next(channel);
  }

  public channel$ = new BehaviorSubject<Channel>(null);

  constructor(private route: ActivatedRoute, private state: WebPlayerState) {}

  ngOnInit() {
    if (this.state.scrollContainer) {
      this.state.scrollContainer.nativeElement.scrollTop = 0;
    }

    this.route.data.subscribe((data) => {
      if (data.api && data.api.channel) {
        if (data.api.channel.name == 'Popular Albums') {
          data.api.channel.name = 'Popular Packs';
        } else if (data.api.channel.name == 'Popular Tracks') {
          data.api.channel.name = 'Popular Sounds';
        } else if (
          data.api.channel.slug === 'genre' &&
          data.api.channel.name.indexOf('Artists') >= 0
        ) {
          data.api.channel.name = data.api.channel.name.replace(
            'Artists',
            'Sounds'
          );
        }
        this.channel$.next(data.api.channel);
      }
    });
  }
}
