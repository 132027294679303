<div class="player-header" *ngIf="!state.isMobile">
    <div class="logo">
        <a class="logo-container" routerLink="/">
            <img [src]="settings.get('branding.logo_dark')" alt="Site logo">
        </a>
    </div>
    <div class="search-bar">
        <form class="search-bar-container" (ngSubmit)="searchPanel.goToSearchPage()">
            <label for="main-search" class="hidden" trans>Main site search</label>
            <input id="main-search" placeholder="Search..." [formControl]="searchPanel.searchQuery" autocomplete="off" trans-placeholder>
    
            <mat-icon svgIcon="search" class="search-bar-icon" [class.hidden]="searchPanel.searching || searchPanel.searchQuery.value" (click)="searchPanel.goToSearchPage()"></mat-icon>
    
            <button type="button" class="no-style search-bar-icon" (click)="searchPanel.close()">
                <mat-icon svgIcon="close" [class.hidden]="searchPanel.searching || ! searchPanel.searchQuery.value"></mat-icon>
            </button>
    
            <loading-indicator [isVisible]="searchPanel.searching" class="small"></loading-indicator>
        </form>
    </div>
    <div class="user-menu">
        <div class="auth-container">
            <div *ngIf="currentUser.isLoggedIn()" class="current-user">
                <a [routerLink]="profileLink" class="img-container img-top">
                    <span [matTooltip]="CreditTooltip | trans" >{{ WalletBalance }} CR &nbsp;</span>
                    <img [src]="currentUser.get('avatar')" alt="user avatar">
                </a>
                <a class="name hover-underline" [routerLink]="profileLink">{{currentUser.get('display_name')}}</a>
    
                <button
                        mat-icon-button
                        [matMenuTriggerFor]="navSidebarMenu"
                        class="settings-button"
                        [matBadge]="(currentUser.model$ | async)?.unread_notifications_count"
                        matBadgeColor="accent"
                        matBadgeSize="small"
                        [matBadgeHidden]="(currentUser.model$ | async)?.unread_notifications_count < 1"
                >
                    <mat-icon svgIcon="settings"></mat-icon>
                </button>
    
                <mat-menu #navSidebarMenu="matMenu">
                   <nav-sidebar-user-menu></nav-sidebar-user-menu>
                </mat-menu>
            </div>
    
            <ng-container *ngIf="!currentUser.isLoggedIn()">
                <a routerLink="/login" mat-raised-button color="accent" trans>Login</a>
                <a routerLink="/register" *ngIf="!settings.get('registration.disable')" mat-button trans>Register</a>
            </ng-container>
        </div>
    </div>
</div>
<div class="player-content" [class.mobile]="state.isMobile">
    <ng-container>
        <nav-sidebar *ngIf="!state.isMobile"></nav-sidebar>
        <mat-drawer-container class="page-content">
            <mat-drawer mode="over" [opened]="searchPanel.isOpen" (closedStart)="searchPanel.close()" [autoFocus]="false" class="drawer-horizontal" >
                <search-slideout-panel></search-slideout-panel>
            </mat-drawer>
            <mat-drawer-content>
                <div class="page-wrapper" #scrollContainer>
                    <div class="space-wrapper">
                        <router-outlet></router-outlet>
                    </div>
                </div>
                <queue-sidebar *ngIf="!state.isMobile" [class.full-height]="shouldHideVideo"></queue-sidebar>
            </mat-drawer-content>
        </mat-drawer-container>
    </ng-container>
</div>

<player-controls *ngIf="!state.isMobile"></player-controls>
<mobile-player-controls *ngIf="state.isMobile"></mobile-player-controls>

<fullscreen-overlay [class.mobile]="state.isMobile" ngClass="strategy-{{player.state.activePlaybackStrategy}}" [class.hide-video]="shouldHideVideo">
    <div class="player-container youtube-player" id="youtube-player"></div>
    <div class="player-container html5-player">
        <div class="img-container">
            <playback-control-button (play)="player.play()" (pause)="player.pause()" [playing]="player.isPlaying()"></playback-control-button>
        </div>
    </div>
    <div class="player-container soundcloud-player"></div>
</fullscreen-overlay>

<loading-indicator [isVisible]="state.loading" class="overlay"></loading-indicator>
