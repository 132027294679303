export class FilterTrack {
  key?: string;
  keyType?: string;
  bpm?: number;
  bpmMin?: number;
  bpmMax?: number;
  instrument?: string;

  constructor(params: FilterTrack) {
    for (let name in params) {
      this[name] = params[name];
    }
  }
}
