import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterTrack } from 'src/app/models/FilterTrack';

@Component({
  selector: 'track-filter',
  templateUrl: './track-filter.component.html',
  styleUrls: ['./track-filter.component.scss'],
})
export class TrackFilterComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}
  @Output('onFilter') onFilter = new EventEmitter<FilterTrack>();
  @Output('onRandomSelect') onRandomSelect = new EventEmitter();
  checkRandom: boolean = false;
  routeSlug: string | undefined;
  routeURL: string | undefined;
  randomSupportedRoutes = [
    'genre',
    'new-releases',
    'popular-tracks',
    'discover',
  ];

  KeyLabel = 'Key';
  BPMLabel = 'BPM';
  InstrumentLabel = 'Instrument';
  keyType = '';
  key = '';
  isExact = true;
  MinBPM = '';
  MaxBPM = '';
  BPM = '';
  Instrument = '';

  ngOnInit() {
    this.routeURL = this.router.url;
    // Subscribe to the route parameters
    this.route.paramMap.subscribe((params) => {
      // Get the value of the 'slug' parameter
      this.routeSlug = params.get('slug');
    });
  }

  selectKey(event, key) {
    event.stopPropagation();
    this.key = key;
  }

  clearKey(event) {
    event.stopPropagation();
    this.key = '';
    this.keyType = '';
  }

  removeKey(event) {
    event.stopPropagation();
    this.clearKey(event);
    this.KeyLabel = 'Key';
    this.filterData();
  }

  get isSave() {
    return !(this.key || this.keyType);
  }

  bpmFilterClick(event, value) {
    event.stopPropagation();
    this.isExact = value;
  }

  get isBPMSave() {
    let isDisable = true;

    if (this.isExact && this.BPM) isDisable = false;
    else if (!this.isExact && this.MinBPM && this.MaxBPM) isDisable = false;

    return isDisable;
  }

  clearBPM(event) {
    event.stopPropagation();
    this.bpmFilterClick(event, true);
    this.BPM = '';
    this.MaxBPM = '';
    this.MinBPM = '';
  }

  removeBPM(event) {
    this.clearBPM(event);
    this.BPMLabel = 'BPM';
    this.filterData();
  }

  keySaved() {
    this.KeyLabel = this.key + ' ' + this.keyType;
    this.filterData();
  }

  bpmSaved() {
    this.BPMLabel =
      (this.isExact ? this.BPM : this.MinBPM + ' - ' + this.MaxBPM) + ' BPM';
    this.filterData();
  }

  filterData() {
    this.onFilter.emit(
      new FilterTrack({
        key: this.key,
        keyType: this.keyType,
        bpm: Number(this.BPM),
        bpmMin: Number(this.MinBPM),
        bpmMax: Number(this.MaxBPM),
        instrument: this.Instrument,
      })
    );
  }

  onSelectRandom() {
    this.checkRandom = !this.checkRandom;
    this.onRandomSelect.emit(this.checkRandom);
  }

  removeInstrument(event) {
    this.clearInstrument(event);
    this.InstrumentLabel = 'Instrument';
    this.filterData();
  }

  clearInstrument(event) {
    event.stopPropagation();
    this.Instrument = '';
  }

  InstrumentSaved() {
    this.InstrumentLabel = this.Instrument;
    this.filterData();
  }
}
