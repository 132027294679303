import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {SearchSlideoutPanel} from './search/search-slideout-panel/search-slideout-panel.service';
import {Player} from './player/player.service';
import {WebPlayerState} from './web-player-state.service';
import {FullscreenOverlay} from './fullscreen-overlay/fullscreen-overlay.service';
import {Settings} from '@common/core/config/settings.service';
import {WebPlayerImagesService} from './web-player-images.service';
import {OverlayContainer} from '@angular/cdk/overlay';
import {Router, Scroll} from '@angular/router';
import {filter, pairwise} from 'rxjs/operators';
import { AppCurrentUser } from '../app-current-user';
import { WebPlayerUrls } from './web-player-urls.service';
import { UserProfileService } from './users/user-profile.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'web-player',
    templateUrl: './web-player.component.html',
    styleUrls: ['./web-player.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {'id': 'web-player'}
})
export class WebPlayerComponent implements OnInit, OnDestroy {
    @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef<HTMLElement>;

    public shouldHideVideo = false;
    public profileLink: (string|number)[];
    public WalletBalance = 0 ;
    public EarnedCredits = 0 ;
    private subscriptions: Subscription[] = [];
    CreditTooltip = "Total Credit\'s";

    constructor(
        public searchPanel: SearchSlideoutPanel,
        public player: Player,
        private renderer: Renderer2,
        public state: WebPlayerState,
        private overlay: FullscreenOverlay,
        public settings: Settings,
        private wpImages: WebPlayerImagesService,
        private overlayContainer: OverlayContainer,
        private router: Router,
        public currentUser: AppCurrentUser,
        public urls: WebPlayerUrls,
        public userProfile: UserProfileService,
    ) {}

    ngOnInit() {
        this.player.init();
        this.overlay.init();
        this.shouldHideVideo = this.settings.get('player.hide_video');
        this.overlayContainer.getContainerElement().classList.add('web-player-theme');
        this.resetScrollTop();
        this.state.scrollContainer = this.scrollContainer;
        this.getUserWalletInfo();

        if (this.currentUser.isArtist()) {
            this.profileLink = this.urls.artist(this.currentUser.primaryArtist());
        } else {
            this.profileLink = this.urls.user(this.currentUser.getModel());
        }

        const subWallet =  this.userProfile.userWallet$.subscribe((res:any)=>{
            if(res){
                if(res.WalletBalance && res.WalletBalance>0){
                    this.WalletBalance = res.WalletBalance;
                }
                if(res.EarnedCredits && res.EarnedCredits>0){
                    this.EarnedCredits = res.EarnedCredits;
                }
            }
        });
        this.subscriptions.push(subWallet);
    }

    ngOnDestroy() {
        this.player.destroy();
        this.overlay.destroy();
        this.overlayContainer.getContainerElement().classList.remove('web-player-theme');

        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    }

    private resetScrollTop() {
        this.router.events
            .pipe(filter(e => e instanceof Scroll), pairwise())
            .subscribe((events: [Scroll, Scroll]) => {
                // only scroll to top on navigation if base url and not query params changed
                const basePrevious = events[0].routerEvent.urlAfterRedirects.split('?')[0],
                    baseCurrent = events[1].routerEvent.urlAfterRedirects.split('?')[0];
                if (basePrevious !== baseCurrent) {
                    this.state.scrollContainer.nativeElement.scrollTop = 0;
                    this.state.scrollContainer.nativeElement.scrollLeft = 0;
                }
            });
    }

    getUserWalletInfo(){
        
        if (this.currentUser.isArtist()) {
            this.userProfile.getArtistCreditInfo({ artist_id : this.currentUser.primaryArtist().id + ''  }).subscribe((res:any)=>{
                if(res.status){
                    this.EarnedCredits = res.data.earnedPoints;
                    this.WalletBalance = res.data.creditPoints;
                    this.userProfile.userWallet$.next({EarnedCredits: res.data.earnedPoints, WalletBalance: res.data.creditPoints });
                }
            })
        }
        else{
            this.userProfile.getWalletInfo({ user_id : this.currentUser.getModel().id }).subscribe((res:any)=>{
                if(res.status){
                    this.WalletBalance = res.data;
                    this.userProfile.userWallet$.next({WalletBalance: res.data});
                }
            });
        }

       
        
    }
}
