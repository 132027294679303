<div class="filter-container">
  <button
    mat-stroked-button
    [matMenuTriggerFor]="keyMenu"
    color="outline"
    class="btn-spc"
    [ngClass]="{ 'filter-btn-value': KeyLabel != 'Key' }"
  >
    {{ KeyLabel }}
    <div *ngIf="KeyLabel == 'Key'" class="arrow-down"></div>
    <mat-icon
      *ngIf="KeyLabel != 'Key'"
      svgIcon="close"
      style="width: 15px"
      (click)="removeKey($event)"
    ></mat-icon>
  </button>
  <button
    mat-stroked-button
    [matMenuTriggerFor]="bpmMenu"
    color="outline"
    class="btn-spc"
    [ngClass]="{ 'filter-btn-value': BPMLabel != 'BPM' }"
  >
    {{ BPMLabel }}
    <div *ngIf="BPMLabel == 'BPM'" class="arrow-down"></div>
    <mat-icon
      *ngIf="BPMLabel != 'BPM'"
      svgIcon="close"
      style="width: 15px"
      (click)="removeBPM($event)"
    ></mat-icon>
  </button>

  <button
    mat-stroked-button
    [matMenuTriggerFor]="instrumentMenu"
    color="outline"
    class="btn-spc"
    [ngClass]="{ 'filter-btn-value': InstrumentLabel != 'Instrument' }"
  >
    {{ InstrumentLabel }}
    <div *ngIf="InstrumentLabel == 'Instrument'" class="arrow-down"></div>
    <mat-icon
      *ngIf="InstrumentLabel != 'Instrument'"
      svgIcon="close"
      style="width: 15px"
      (click)="removeInstrument($event)"
    ></mat-icon>
  </button>

  <button
    *ngIf="randomSupportedRoutes.includes(routeSlug) || routeURL === '/'"
    mat-stroked-button
    color="outline"
    class="btn-spc"
    [ngClass]="{ 'filter-btn-value': checkRandom }"
    (click)="onSelectRandom()"
  >
    Random
  </button>

  <!-- TODO: We need to add that button there -->
</div>

<mat-menu #keyMenu="matMenu">
  <form class="keys-form" (keydown.tab)="$event.stopPropagation()">
    <mat-tab-group (click)="$event.stopPropagation(); (false)">
      <mat-tab label="Flat Keys" (click)="$event.stopPropagation(); (false)">
        <ng-template matTabContent>
          <div class="button-section">
            <button
              mat-stroked-button
              color="outline"
              [ngClass]="{ active: key == 'D♭' }"
              (click)="selectKey($event, 'D♭')"
              class="keys flat"
            >
              D
            </button>
            <button
              mat-stroked-button
              color="outline"
              [ngClass]="{ active: key == 'E♭' }"
              (click)="selectKey($event, 'E♭')"
              class="keys flat"
            >
              E
            </button>
            <button
              mat-stroked-button
              color="outline"
              [ngClass]="{ active: key == 'G♭' }"
              (click)="selectKey($event, 'G♭')"
              class="keys flat"
            >
              G
            </button>
            <button
              mat-stroked-button
              color="outline"
              [ngClass]="{ active: key == 'A♭' }"
              (click)="selectKey($event, 'A♭')"
              class="keys flat"
            >
              A
            </button>
            <button
              mat-stroked-button
              color="outline"
              [ngClass]="{ active: key == 'B♭' }"
              (click)="selectKey($event, 'B♭')"
              class="keys flat"
            >
              B
            </button>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Sharp Keys" (click)="$event.stopPropagation(); (false)">
        <ng-template matTabContent>
          <div class="button-section">
            <button
              mat-stroked-button
              color="outline"
              [ngClass]="{ active: key == 'C#' }"
              (click)="selectKey($event, 'C#')"
              class="keys sharp"
            >
              C
            </button>
            <button
              mat-stroked-button
              color="outline"
              [ngClass]="{ active: key == 'D#' }"
              (click)="selectKey($event, 'D#')"
              class="keys sharp"
            >
              D
            </button>
            <button
              mat-stroked-button
              color="outline"
              [ngClass]="{ active: key == 'F#' }"
              (click)="selectKey($event, 'F#')"
              class="keys sharp"
            >
              F
            </button>
            <button
              mat-stroked-button
              color="outline"
              [ngClass]="{ active: key == 'G#' }"
              (click)="selectKey($event, 'G#')"
              class="keys sharp"
            >
              G
            </button>
            <button
              mat-stroked-button
              color="outline"
              [ngClass]="{ active: key == 'A#' }"
              (click)="selectKey($event, 'A#')"
              class="keys sharp"
            >
              A
            </button>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <div class="keys-section">
      <button
        mat-stroked-button
        class="keys"
        [ngClass]="{ active: key == 'C' }"
        (click)="selectKey($event, 'C')"
        color="outline"
      >
        C
      </button>
      <button
        mat-stroked-button
        class="keys"
        [ngClass]="{ active: key == 'D' }"
        (click)="selectKey($event, 'D')"
        color="outline"
      >
        D
      </button>
      <button
        mat-stroked-button
        class="keys"
        [ngClass]="{ active: key == 'E' }"
        (click)="selectKey($event, 'E')"
        color="outline"
      >
        E
      </button>
      <button
        mat-stroked-button
        class="keys"
        [ngClass]="{ active: key == 'F' }"
        (click)="selectKey($event, 'F')"
        color="outline"
      >
        F
      </button>
      <button
        mat-stroked-button
        class="keys"
        [ngClass]="{ active: key == 'G' }"
        (click)="selectKey($event, 'G')"
        color="outline"
      >
        G
      </button>
      <button
        mat-stroked-button
        class="keys"
        [ngClass]="{ active: key == 'A' }"
        (click)="selectKey($event, 'A')"
        color="outline"
      >
        A
      </button>
      <button
        mat-stroked-button
        class="keys"
        [ngClass]="{ active: key == 'B' }"
        (click)="selectKey($event, 'B')"
        color="outline"
      >
        B
      </button>
    </div>

    <br />
    <button
      mat-stroked-button
      color="outline"
      [ngClass]="{ active: keyType == 'maj' }"
      class="btn-spc"
      (click)="$event.stopPropagation(); keyType = 'maj'"
    >
      Major
    </button>
    <button
      mat-stroked-button
      color="outline"
      [ngClass]="{ active: keyType == 'min' }"
      class="btn-spc"
      (click)="$event.stopPropagation(); keyType = 'min'"
    >
      Minor
    </button>
    <br />
    <hr />
    <button mat-button class="btn-spc" (click)="clearKey($event)">Clear</button>
    <button
      mat-raised-button
      color="primary"
      class="btn-spc"
      [disabled]="isSave"
      (click)="keySaved()"
    >
      Save
    </button>
  </form>
</mat-menu>

<mat-menu #bpmMenu="matMenu" [overlapTrigger]="false">
  <form class="keys-form" (keydown.tab)="$event.stopPropagation()">
    <div class="keys-section">
      <div class="field-section">
        <mat-radio-group class="bpm-radio-group">
          <mat-radio-button
            [checked]="isExact"
            value="true"
            class="bpm-radio-button"
            (click)="bpmFilterClick($event, true)"
          >
            Exact
          </mat-radio-button>
          <mat-radio-button
            [checked]="!isExact"
            value="false"
            class="bpm-radio-button"
            (click)="bpmFilterClick($event, false)"
          >
            Range
          </mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="isExact">
          <input
            matInput
            type="number"
            min="0"
            max="99999"
            [value]="BPM"
            (input)="BPM = $event.target.value"
            (click)="$event.stopPropagation()"
          />
          <mat-placeholder class="placeholder">BPM</mat-placeholder>
        </mat-form-field>

        <div *ngIf="!isExact">
          <mat-form-field>
            <input
              matInput
              type="number"
              min="0"
              max="99999"
              [value]="MinBPM"
              (input)="MinBPM = $event.target.value"
              (click)="$event.stopPropagation()"
            />
            <mat-placeholder class="placeholder">Min</mat-placeholder>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              type="number"
              min="0"
              max="99999"
              [value]="MaxBPM"
              (input)="MaxBPM = $event.target.value"
              (click)="$event.stopPropagation()"
            />
            <mat-placeholder class="placeholder">Max</mat-placeholder>
          </mat-form-field>
        </div>
        <br />
        <br />
        <button mat-button class="btn-spc" (click)="clearBPM($event)">
          Clear
        </button>
        <button
          mat-raised-button
          color="primary"
          class="btn-spc"
          [disabled]="isBPMSave"
          (click)="bpmSaved()"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</mat-menu>

<mat-menu #instrumentMenu="matMenu" [overlapTrigger]="false">
  <form class="keys-form" (keydown.tab)="$event.stopPropagation()">
    <div class="keys-section">
      <div class="field-section">
        <mat-form-field>
          <input
            matInput
            type="text"
            [value]="Instrument"
            (input)="Instrument = $event.target.value"
            (click)="$event.stopPropagation()"
          />
          <mat-placeholder class="placeholder">Instrument</mat-placeholder>
        </mat-form-field>
        <br />
        <br />
        <button mat-button class="btn-spc" (click)="clearInstrument($event)">
          Clear
        </button>
        <button
          mat-raised-button
          color="primary"
          class="btn-spc"
          [disabled]="!Instrument"
          (click)="InstrumentSaved()"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</mat-menu>
