<div class="header" *ngIf="!multipleTracksSelected()">
    <media-image [media]="data.item"></media-image>
    <div class="meta">
        <a class="primary-name hover-underline" [routerLink]="urls.track(data.item)">{{
            data.item.name
        }}</a>
        <artists-links-list
            class="secondary-name"
            [artists]="data.item.artists"
        ></artists-links-list>
    </div>
</div>

<div class="context-menu-panel primary-panel" [class.hidden]="!activePanelIs('primary')">
    <div class="panel-body">
        <ng-content></ng-content>
        <div
            class="context-menu-item"
            (click)="goToTrackRadio()"
            *ngIf="
                !multipleTracksSelected() &&
                !settings.get('player.hide_radio_button') &&
                settings.get('artist_provider') === 'spotify'
            "
            trans
        >
            Go to Sound Radio
        </div>
        <div
            class="context-menu-item"
            (click)="addToQueue()"
            *ngIf="!inQueue() || multipleTracksSelected()"
            trans
        >
            Add to Queue
        </div>
        <div
            class="context-menu-item"
            (click)="removeFromQueue()"
            *ngIf="inQueue() && !multipleTracksSelected()"
            trans
        >
            Remove from Queue
        </div>
        <div class="context-menu-item" (click)="openPanel('playlist'); $event.stopPropagation()">
            <span trans>Add to Playlist</span> <mat-icon svgIcon="keyboard-arrow-right"></mat-icon>
        </div>
        <div
            class="context-menu-item"
            (click)="saveToLibrary()"
            *ngIf="!inLibrary() || multipleTracksSelected()"
            trans
        >
            Save to Your Music
        </div>
        <div
            class="context-menu-item"
            (click)="removeFromLibrary()"
            *ngIf="inLibrary() && !multipleTracksSelected()"
            trans
        >
            Remove from Your Music
        </div>
        <div
            class="context-menu-item"
            (click)="showLyricsModal()"
            *ngIf="!multipleTracksSelected() && !settings.get('player.hide_lyrics')"
            trans
        >
            Show Lyrics
        </div>
        <div
            class="context-menu-item"
            (click)="copyLinkToClipboard()"
            *ngIf="!multipleTracksSelected()"
            trans
        >
            Copy Sound Link
        </div>
        <div
            class="context-menu-item"
            (click)="openShareModal()"
            *ngIf="!multipleTracksSelected()"
            trans
        >
            Share
        </div>
        <div
            class="context-menu-item"
            (click)="downloadTrack()"
            *ngIf="!multipleTracksSelected() && !shouldHideDownloadButton && data.item.url"
            trans
        >
            Download
        </div>
        <a
            class="context-menu-item"
            [routerLink]="urls.editTrack(data.item)"
            *ngIf="canEditTrack"
            trans
            >Edit</a
        >
        <div class="context-menu-item" (click)="maybeDeleteTrack()" *ngIf="canDeleteTrack" trans>
            Delete
        </div>
    </div>
</div>

<context-menu-playlist-panel
    class="context-menu-panel"
    [class.hidden]="!activePanelIs('playlist')"
    [tracks]="getTracks()"
    (close$)="openPanel('primary')"
></context-menu-playlist-panel>
