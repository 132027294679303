<div
  class="sub-channel"
  *ngIf="channel.config.contentModel === modelTypes.channel"
>
  <ng-container *ngFor="let channel of channel.content.data">
    <h2 *ngIf="!channel.config.hideTitle">
      <a [routerLink]="urls.channel(channel)" trans>{{
        formatName(channel.name)
      }}</a>
      <mat-icon svgIcon="keyboard-arrow-right"></mat-icon>
    </h2>
    <channel-content [channel]="channel" [nested]="true"></channel-content>
  </ng-container>
</div>
<ng-container *ngIf="channel.config.contentModel !== modelTypes.channel">
  <channel-media-grid
    *ngIf="
      (channel.config.carouselWhenNested && nested) ||
      channel.config.layout === 'grid'
    "
    [channel]="channel"
    [nested]="nested"
  ></channel-media-grid>
  <ng-container *ngIf="!(channel.config.carouselWhenNested && nested)">
    <channel-track-list
      *ngIf="channel.config.layout === 'trackList'"
      [channel]="channel"
      [nested]="nested"
    ></channel-track-list>
    <channel-track-table
      *ngIf="channel.config.layout === 'trackTable'"
      [channel]="channel"
      [nested]="nested"
    ></channel-track-table>
  </ng-container>
</ng-container>

<div
  class="no-results-message"
  *ngIf="!channel.content?.data?.length && !nested"
>
  <mat-icon class="icon" svgIcon="album"></mat-icon>
  <div class="text">
    <div class="main" trans>Nothing to display.</div>
    <div class="secondary" trans>
      <span trans>Seems like this channel does not have any content yet.</span>
      &ngsp;
      <a
        class="hover-underline"
        [routerLink]="['/admin/channels', channel.id]"
        *ngIf="user.hasPermission('channels.edit')"
        trans
        >Configure</a
      >
    </div>
  </div>
</div>
