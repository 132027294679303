import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Track } from '../../../models/Track';
import { Player } from '../../player/player.service';
import { FormattedDuration } from '../../player/formatted-duration.service';
import { Album } from '../../../models/Album';
import { WpUtils } from '../../web-player-utils';
import { WebPlayerUrls } from '../../web-player-urls.service';
import { TrackContextMenuComponent } from '../track-context-menu/track-context-menu.component';
import { PlaylistTrackContextMenuComponent } from '../../playlists/playlist-track-context-menu/playlist-track-context-menu.component';
import { SelectedTracks } from './selected-tracks.service';
import { Subscription } from 'rxjs';
import { WebPlayerState } from '../../web-player-state.service';
import { BrowserEvents } from '@common/core/services/browser-events.service';
import { ContextMenu } from '@common/core/ui/context-menu/context-menu.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { A, DELETE, DOWN_ARROW } from '@angular/cdk/keycodes';
import { DatatableService } from '@common/datatable/datatable.service';
import { Settings } from '@common/core/config/settings.service';
import { AppCurrentUser } from 'src/app/app-current-user';
import { downloadFileFromUrl } from '@common/uploads/utils/download-file-from-url';
import { FilterTrack } from 'src/app/models/FilterTrack';
import { PlayerQueue } from '../../player/player-queue.service';
import { UserProfileService } from '../../users/user-profile.service';
import { Toast } from '@common/core/ui/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Howl } from 'howler';

@Component({
  selector: 'track-table',
  templateUrl: './track-table.component.html',
  styleUrls: ['./track-table.component.scss'],
  providers: [SelectedTracks],
})
export class TrackTableComponent implements OnInit, OnDestroy {
  protected subscriptions: Subscription[] = [];

  @Input() dataSource: DatatableService<Track>;
  @Input() album: Album;

  @Input() showTrackImage = true;
  @Input() showArtist = false;
  @Input() showAlbum = false;
  @Input() showPopularity = false;
  @Input() showAddedAt = false;
  @Input() showHeader = true;
  @Input() overrideQueue = true;

  @Input() queueItemId: string;
  @Input() contextMenuParams = { type: 'track', extra: {} };
  @Input() select: Track;

  @Output() delete = new EventEmitter();
  @Output() orderChanged: EventEmitter<CdkDragDrop<any>> = new EventEmitter();
  private preloadedSounds: Set<string> = new Set<string>();
  public totalRecords = 0;
  routeSlug: string | undefined;
  routeURL: string | undefined;
  randomSupportedRoutes = [
    'genre',
    'new-releases',
    'popular-tracks',
    'discover',
  ];

  public reorderEnabled = false;
  public shouldHideDownloadButton = true;
  filterBy: FilterTrack;
  isPlayedOnce = false;
  currentFilter: string | undefined;
  constructor(
    public player: Player,
    private duration: FormattedDuration,
    public urls: WebPlayerUrls,
    private contextMenu: ContextMenu,
    private zone: NgZone,
    private el: ElementRef,
    public selectedTracks: SelectedTracks,
    private browserEvents: BrowserEvents,
    public state: WebPlayerState,
    public settings: Settings,
    public currentUser: AppCurrentUser,
    public queue: PlayerQueue,
    private cdr: ChangeDetectorRef,
    private user_profile: UserProfileService,
    private toast: Toast,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // this.preloadSounds();
    this.bindHammerEvents();
    this.bindKeyboardShortcuts();
    this.reorderEnabled =
      !!this.orderChanged.observers.length && !this.state.isMobile;
    if (this.select) {
      this.selectedTracks.add(this.select);
    }
    this.shouldHideDownloadButton = !(
      this.settings.get('player.enable_download') ||
      this.currentUser.hasPermission('music.download')
    );
    this.currentFilter = this.route.snapshot.params.filter || '';
    this.dataSource.totalRecords$.subscribe((total) => {
      this.totalRecords = total;
    });

    this.routeURL = this.router.url;
    // Subscribe to the route parameters
    this.route.paramMap.subscribe((params) => {
      // Get the value of the 'slug' parameter
      this.routeSlug = params.get('slug');
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  // preloadSounds() {
  //   this.dataSource.data$.subscribe((tracks) => {
  //     tracks.forEach((track) => {
  //       console.log('track', track);
  //       if (track.url) {
  //         if (!(this.preloadedSounds as Set<string>).has(track.url)) {
  //           const sound = new Howl({
  //             src: [track.url],
  //             html5: true,
  //             preload: true,
  //           });
  //         }

  //         // Add the URL to the set to mark it as preloaded
  //         this.preloadedSounds.add(track.url);
  //       }
  //     });
  //   });
  // }

  // preloadSounds() {
  //   this.dataSource.data$.subscribe((tracks) => {
  //     tracks.splice(0, 3).forEach((track) => {
  //       if (track.url) {
  //         if (!(this.preloadedSounds as Set<string>).has(track.url)) {
  //           const audio = new Audio(track.url);
  //           audio.preload = 'auto';
  //           audio.load();
  //         }
  //         // Add the URL to the set to mark it as preloaded
  //         this.preloadedSounds.add(track.url);
  //       }
  //     });
  //   });
  // }

  public getTracks(): Track[] {
    return this.dataSource.data$.value;
  }

  public trackIsPlaying(track: Track) {
    return this.player.isPlaying() && this.player.cued(track);
  }

  public playTrack(track: Track, index: number) {
    if (this.player.cued(track)) {
      this.player.play();
    } else {
      this.playFrom(index);
    }
  }

  public async toggleTrackPlayback(track: Track, index: number) {
    if (this.trackIsPlaying(track)) {
      this.player.pause();
    } else {
      this.playTrack(track, index);
    }
  }

  public showContextMenu(track: Track, e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.contextMenu.open(this.getContextMenuComponent(), e.target, {
      data: this.getContextMenuParams(track),
    });
  }

  /**
   * Get params needed to open context menu for track.
   */
  public getContextMenuParams(track: Track) {
    return Object.assign(
      {
        item: track,
        type: this.contextMenuParams.type,
        selectedTracks: this.selectedTracks,
      },
      this.contextMenuParams.extra
    );
  }

  /**
   * Get context menu component based on specified type.
   */
  private getContextMenuComponent() {
    if (this.contextMenuParams.type === 'playlistTrack') {
      return PlaylistTrackContextMenuComponent;
    } else {
      return TrackContextMenuComponent;
    }
  }

  /**
   * Add tracks from specified index to player queue and start playback.
   */
  private playFrom(index: number) {
    if (this.overrideQueue) {
      let tracks = this.getTracks().slice(index, this.getTracks().length);
      tracks = WpUtils.assignAlbumToTracks(tracks, this.album);

      this.player
        .overrideQueue({ tracks, queuedItemId: this.queueItemId })
        .then(() => {
          this.player.play();
        });
    } else {
      this.player.stop();
      this.player.queue.set(index);
      this.player.play();
    }
  }

  public formatTrackDuration(track: Track) {
    return this.duration.fromMilliseconds(track.duration);
  }

  /**
   * Bind handlers to needed hammer.js events.
   */
  private bindHammerEvents() {
    let hammer, singleTap, doubleTap;

    this.zone.runOutsideAngular(() => {
      hammer = new Hammer.Manager(this.el.nativeElement);
      singleTap = new Hammer.Tap({ event: 'singletap' });
      doubleTap = new Hammer.Tap({ event: 'doubletap', taps: 2 });
      hammer.add([doubleTap, singleTap]);
    });

    // select track on tap or multiple tracks when ctrl is pressed
    hammer.on('singletap', (e: HammerInput) => {
      this.zone.run(() => {
        const data = this.getTrackFromEvent(e);

        if (!data || !data.track) return;

        if (this.state.isMobile && !e.target.closest('.track-options-button')) {
          const i = this.getTracks().findIndex((t) => t.id === data.track.id);
          this.toggleTrackPlayback(data.track, i);
        }

        if (!e.srcEvent.ctrlKey) {
          this.selectedTracks.clear();
          this.selectedTracks.add(data.track);
        } else {
          this.selectedTracks.toggle(data.track);
        }
      });
    });

    // play track on double tap
    hammer.on('doubletap', (e) => {
      this.zone.run(() => {
        const data = this.getTrackFromEvent(e);
        if (!data) return;
        this.playTrack(data.track, data.index);
      });
    });

    // deselect all tracks when clicked outside of track list.
    const sub = this.browserEvents.globalClick$.subscribe((e) => {
      if (!(e.target as HTMLElement).closest('.track-list-row')) {
        this.selectedTracks.clear();
      }
    });

    this.subscriptions.push(sub);
  }

  /**
   * Get track from specified hammer tap event.
   */
  private getTrackFromEvent(e: HammerInput): { track: Track; index: number } {
    if (!e.target) return;
    const row = e.target.closest('.track-list-row');
    if (!row) return;
    const id = +row.getAttribute('data-id');
    const i = this.getTracks().findIndex((track) => track.id === id);
    return { track: this.getTracks()[i], index: i };
  }

  /**
   * Initiate tracks list shortcuts.
   */
  private bindKeyboardShortcuts() {
    const sub = this.browserEvents.globalKeyDown$.subscribe(
      (e: KeyboardEvent) => {
        // ctrl+a - select all tracks
        if (e.ctrlKey && e.keyCode === A) {
          this.getTracks().forEach((track) => this.selectedTracks.add(track));
          e.preventDefault();

          // delete - fire delete event
        } else if (e.keyCode === DELETE && !this.selectedTracks.empty()) {
          this.delete.emit(this.selectedTracks.all());
          this.selectedTracks.clear();
          e.preventDefault();
        } else if (e.keyCode === DOWN_ARROW) {
          const track = this.getTracks()[0];
          if (track && !this.isPlayedOnce) {
            this.playTrack(track, 0);
            this.isPlayedOnce = true;
          }
          e.preventDefault();
        }
      }
    );

    this.subscriptions.push(sub);
  }

  public trackByFn = (i: number, track: Track) => track.id;

  public downloadCurrentTrack(track) {
    // check his balance
    // then decide.
    // on download update Credit
    var isArtists = false;
    if (track.artists && track.artists.length > 0) {
      isArtists = this.currentUser.get('id') == track.artists[0].id;
      // current user is downloading his own track.
    }

    if (isArtists || this.currentUser.isAdmin()) {
      downloadFileFromUrl(this.urls.trackDownload(track));
    } else {
      if (this.user_profile.userWallet$.value?.WalletBalance >= 2) {
        this.user_profile
          .downloadTrack({
            user_id: this.currentUser.getModel().id,
            artist_id: track.artists[0].id,
            track_id: track.id,
          })
          .subscribe((res: any) => {
            if (res.status) {
              this.user_profile.userWallet$.next({
                WalletBalance:
                  this.user_profile.userWallet$.value?.WalletBalance - 2,
              });
              downloadFileFromUrl(this.urls.trackDownload(track));
            } else {
              this.toast.open(res.message);
            }
          });
      } else {
        this.toast.open("You didn't have enough balance to continue download.");
      }
    }
  }

  onFilterTrack(event: FilterTrack) {
    this.filterBy = event;
  }

  selectRandomTrack(value: boolean) {
    let obj = {
      select_random: value,
      returnContentOnly: true,
      filter: this.currentFilter,
    };

    this.dataSource.reset(obj);
  }

  public get shouldShowWave() {
    return this.settings.get('player.seekbar_type') === 'waveform';
  }

  public IsThisCurrentTrack(track) {
    let currentTrack = this.player.getCuedTrack();
    return (
      currentTrack &&
      currentTrack.id == track.id &&
      currentTrack.name == track.name
    );
  }
}
