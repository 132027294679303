import { Pipe, PipeTransform } from '@angular/core';
import { FilterTrack } from 'src/app/models/FilterTrack';

@Pipe({
  name: 'filterTrack',
})
export class FilterTrackPipe implements PipeTransform {
  transform(items: any[], filter: FilterTrack): any[] {
    if (!items) {
      return [];
    }

    const decodeUnicode = (str: string): string =>
      str.replace(/\\u([\dA-F]{4})/gi, (match, grp) =>
        String.fromCharCode(parseInt(grp, 16))
      );

    if (
      !filter ||
      (!filter.key &&
        !filter.keyType &&
        !filter.instrument &&
        filter.bpm == 0 &&
        filter.bpmMin == 0)
    ) {
      return items;
    }
    var data = [];
    items.forEach((item) => {
      if (
        (filter.keyType == '' || item.keyType == filter.keyType) &&
        (filter.key == '' ||
          (item.key != null &&
            filter.key != null &&
            decodeUnicode(item?.key.toLowerCase()) ==
              decodeUnicode(filter?.key.toLowerCase()))) &&
        (!filter.instrument || item.instrument == filter.instrument) &&
        (filter.bpm < 1 || item.bpm == filter.bpm) &&
        (filter.bpmMin < 1 ||
          (item.bpm >= filter.bpmMin && item.bpm <= filter.bpmMax))
      ) {
        data.push(item);
      }
    });
    return data;
  }
}
